import React, { useState, useContext } from 'react'
import renderBlok from '@renderBlok'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Box,
  Button,
  Select,
  FormControl,
  InputLabel,
  Input,
  Chip,
  ListSubheader,
  MenuItem,
} from '@material-ui/core'
import { DateRange } from 'react-date-range'
import { TextSm } from '@system'
import classNames from 'classnames'
import PageContext from '@context'
import get from 'lodash/get'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import ACC_THEME from '../../themes'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import Icon from '@system/icon'

dayjs.extend(utc)
dayjs.extend(localizedFormat)

const useStyles = makeStyles((theme) => ({
  filtersMenu: {
    display: 'block',
    background: theme.palette.background.paper,
    margin: (props) =>
      props.isResourcesOrWebinarsModule ||
      props.isEventModule ||
      props.isIntegrationsModule
        ? '0px'
        : '0px 0px 40px',
    padding: '4px',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  filters: {
    maxWidth: '98%',
    margin: '0px auto 8px',
    [theme.breakpoints.up('sm')]: {
      margin: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: (props) =>
        props.isResourcesOrWebinarsModule || props.isEventModule
          ? '100%'
          : '75%',
      margin: 'auto 0px',
    },
  },
  text: {
    alignSelf: 'center',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    padding: '8px',
    display: 'block',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      paddingLeft: (props) => (props.isWebinarsModule ? 'auto' : '24px'),
    },
  },
  button: {
    alignSelf: 'center',
    marginLeft: 'auto',
    padding: '8px 12px 8px 8px',
    whiteSpace: (props) => (props.isResourcesModule ? 'nowrap' : ''),
    '& button': {
      width: '100%',
      background: (props) =>
        props.resetButtonExists && !!props.resetButtonBgColor
          ? props.resetButtonBgColor
          : theme.palette.background.paper,
      '&>span': {
        color: (props) =>
          props.resetButtonExists && !!props.resetButtonColor
            ? props.resetButtonColor
            : theme.palette.text.secondary,
      },
      '&:hover': {
        background: (props) =>
          props.resetButtonExists && !!props.resetButtonBgColor
            ? props.resetButtonBgColor
            : props.resetButtonColor,
      },
      [theme.breakpoints.up('md')]: {
        width: 'auto',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: '8px 12px',
    },
  },
  fullWidthGrid: {
    [theme.breakpoints.up('md')]: {
      maxWidth: (props) => (props.isResourcesModule ? '100%' : '90%'),
    },
  },
  grid: {
    maxWidth: (props) =>
      props.isResourcesOrWebinarsModule
        ? 'calc(20% - 10px)'
        : 'calc(33.33% - 16.66px)',
    flexBasis: (props) =>
      props.isResourcesOrWebinarsModule
        ? 'calc(20% - 10px)'
        : 'calc(33.33% - 16.66px)',
    '&:last-child': {
      maxWidth: (props) =>
        (props.isResourcesOrWebinarsModule || props.isEventModule) && '50px',
      display: (props) =>
        (props.isResourcesOrWebinarsModule || props.isEventModule) && 'flex',
      alignItems: (props) =>
        (props.isResourcesOrWebinarsModule || props.isEventModule) && 'center',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100% !important',
      flexBasis: '100% !important',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: (props) =>
        (props.isResourcesOrWebinarsModule || props.isEventModule) &&
        '100% !important',
      flexBasis: (props) =>
        (props.isResourcesOrWebinarsModule || props.isEventModule) &&
        '100% !important',
    },
  },
  customSelect: {
    '& label': {
      color: theme.palette.background.slate,
      fontSize: theme.typography.body2.fontSize,
      zIndex: 1,
      transform: 'translateY(10px)',
      transformOrigin: 'left',
      paddingLeft: '14px',
      '&.MuiInputLabel-outlined.MuiInputLabel-shrink': {
        color: theme.palette.background.slate,
        transform: 'translateY(10px) !important',
      },
    },
    '& svg': {
      fontSize: '1.5rem',
      color: theme.palette.background.slate,
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
        borderColor: theme.palette.secondary.main,
      },
    },
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '& .MuiFormLabel-filled': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  selectInput: {
    height: '32px',
    backgroundColor: theme.palette.common.white,
    '& fieldset': {
      border: 'none',
    },
    '&>div': {
      fontSize: '11px',
      paddingTop: '4px',
      paddingBottom: '4px',
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  input: {
    fontSize: theme.typography.body2.fontSize,
    height: '32px',
    padding: '14px',
    color: theme.palette.background.slate,
    backgroundColor: theme.palette.common.white,
    '& fieldset': {
      border: 'none',
    },
    '&>div': {
      fontSize: '11px',
      paddingTop: '4px',
      paddingBottom: '4px',
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '& input': {
      cursor: 'pointer',
      '&::-ms-clear': {
        display: 'none',
      },
    },
  },
  datePicker: {
    position: 'absolute',
    top: '100%',
    zIndex: 100,
    backgroundColor: `${theme.palette.background.paper} !important`,
    boxShadow:
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    '& .rdrDateDisplayWrapper': {
      backgroundColor: `${theme.palette.background.paper} !important`,
      '& .rdrDateDisplay': {
        marginBottom: '0px !important',
      },
      '& .rdrDateDisplayItem': {
        borderRadius: '0px',
      },
    },
    '& .rdrMonthAndYearWrapper': {
      '& .rdrNextPrevButton, .rdrPprevButton': {
        borderRadius: '0px',
        backgroundColor: theme.palette.common.white,
      },
      '& .rdrMonthAndYearPickers': {
        flexWrap: 'wrap',
        '& .rdrMonthPicker, .rdrYearPicker': {
          [theme.breakpoints.down('xs')]: {
            margin: '-5px 5px !important',
          },
        },
        '& select': {
          borderRadius: '0px',
        },
      },
    },
    '& .rdrMonths.rdrMonthsVertical': {
      '& .rdrMonth': {
        '& .rdrDays': {
          '& .rdrDay': {
            '& span.rdrStartEdge, span.rdrEndEdge, span.rdrDayStartPreview, span.rdrDayEndPreview, span.rdrDayInPreview, span.rdrStartEdge, span.rdrInRange, span.rdrEndEdge':
              {
                borderRadius: '0px',
              },
            '& span.rdrDayNumber': {
              '& ::after': {
                backgroundColor: theme.palette.primary.main,
              },
            },
          },
        },
        [theme.breakpoints.down('xs')]: {
          width: 'auto !important',
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  dateRangeArrowIcon: {
    position: 'absolute',
    right: '7px',
    top: '4px',
  },
  selectFilterChip: {
    position: 'absolute',
    width: '90%',
    zIndex: '1',
    pointerEvents: 'none',
    borderRadius: '0',
    height: '20px',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&>span': {
      padding: '0',
      fontSize: '12px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '97.5%',
    },
  },
  listSubHeader: {
    pointerEvents: 'none',
    color: theme.palette.text.disabled,
  },
  stateCount: {
    paddingLeft: '40px',
  },
}))
const GalleryFiltersMenuModule = (props) => {
  const {
    filterByText,
    resetButton,
    filtersList,
    isResourcesModule,
    onChangeFilters,
    extractFilterOptions,
    fetchFilterLogicRules,
    filters,
    resetFilters,
    isWebinarsModule,
    staticEventFilters,
    isEventModule,
    onChangeDateRange,
    dateRange,
    isVirtualEvent,
    searchValue,
  } = props.blok

  const isResourcesOrWebinarsModule = isResourcesModule || isWebinarsModule
  const resetButtonExists = resetButton && resetButton.length > 0
  const resetButtonBgColor = get(resetButton, '[0].bgColor.color')
  const resetButtonColor = get(resetButton, '[0].color.color')
  const dateSeparator = '-'
  const groupedSelect = 'grouped-select'

  const classes = useStyles({
    ...props.blok,
    isWebinarsModule: isWebinarsModule,
    resetButtonExists: resetButtonExists,
    resetButtonBgColor: resetButtonBgColor,
    resetButtonColor: resetButtonColor,
    isResourcesOrWebinarsModule: isResourcesOrWebinarsModule,
    isEventModule: isEventModule,
  })

  const countries = [
    { data_source: 'events-canada-provinces', country: 'Canada' },
    { data_source: 'us-states', country: 'United States of America' },
  ]

  const pageContext = useContext(PageContext)
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false)

  const getFilterOptions = (filterName) => {
    return extractFilterOptions && extractFilterOptions(filterName)
  }

  const updateFilterLogicRules = (
    filterLogic,
    multiValueLogic,
    filterDataPath
  ) => {
    fetchFilterLogicRules &&
      fetchFilterLogicRules(filterLogic, multiValueLogic, filterDataPath)
  }

  const setButtonGrid = (filterField, isResourcesModule) => {
    if (!resetButtonExists)
      return filterField.searchBox ? 6 : isResourcesModule ? 2 : 3
    else if (isResourcesModule) return 2
    else return 4
  }

  const isFilterSelected =
    Array.isArray(filters) &&
    filters.some(
      (eachFilter) =>
        (eachFilter.filterSelect && eachFilter.filterSelect.length > 0) ||
        eachFilter.filterSelect.startDate
    )

  const ResetButtonModule = (props) => {
    return (
      (isFilterSelected || !!searchValue) &&
      !!resetButton && (
        <Box className={classes.button} onClick={resetFilters}>
          {resetButton.map((button) => (
            <Button key={button._uid}>{button.text}</Button>
          ))}
        </Box>
      )
    )
  }

  const onChangeDateRangePickerFilter = (
    event,
    filterDataPath,
    filterResultsLogic,
    multipleValuesLogic
  ) => {
    const selectedDateRange = {
      startDate: event.selection.startDate,
      endDate: event.selection.endDate,
    }

    onChangeFilters(selectedDateRange, filterDataPath)
    updateFilterLogicRules(
      filterResultsLogic,
      multipleValuesLogic,
      filterDataPath
    )
    onChangeDateRange(event)
  }

  const onChangeGroupingFilter = (
    event,
    filterDataPath,
    filterResultsLogic,
    multipleValuesLogic
  ) => {
    onChangeFilters(event, filterDataPath)
    updateFilterLogicRules(
      filterResultsLogic,
      multipleValuesLogic,
      filterDataPath
    )
  }

  const GetFilterComponentByFilterType = (props) => {
    const countryEventsData = get(
      pageContext,
      'pageContext.countryEvents.nodes',
      []
    )

    const eventStates =
      pageContext &&
      pageContext.events.filter((event) => {
        return event.content && !!event.content.state
      })

    const formattedEventStates =
      eventStates.length > 1
        ? eventStates.reduce((acc, curr) =>
            acc && acc.length > 0
              ? [...acc, curr.content.state]
              : [acc.content.state, curr.content.state]
          )
        : eventStates.map((eventState) => {
            return eventState.content.state
          })

    const usStates = countryEventsData.filter(
      (eventCountry) => eventCountry.data_source === 'us-states'
    )

    const canadaProvinces = countryEventsData.filter(
      (eventCountry) => eventCountry.data_source === 'events-canada-provinces'
    )

    const allMatchedUSStates =
      usStates.length > 0
        ? countries
            .filter((country) => {
              return !usStates.some((state) => {
                return country.data_source !== state.data_source
              })
            })
            .concat(usStates)
        : []

    const allMatchedCanadaProvinces =
      canadaProvinces.length > 0
        ? countries
            .filter((country) => {
              return !canadaProvinces.some((province) => {
                return country.data_source !== province.data_source
              })
            })
            .concat(canadaProvinces)
        : []

    const CanadaAndUSCountryStates = [
      ...allMatchedUSStates,
      ...allMatchedCanadaProvinces,
    ]

    const values = filters.filter(
      (filter) =>
        filter.filterBy.toLowerCase() === props.filterDataPath.toLowerCase()
    )

    const filterSelectedValue = get(values, '[0].filterSelect')
    const startDate = get(filterSelectedValue, 'startDate')
    const endDate = get(filterSelectedValue, 'endDate')
    const state =
      typeof filterSelectedValue === 'string'
        ? filterSelectedValue
        : get(filterSelectedValue, '[0]')

    const formattedStartAndEndDate = `${dayjs(startDate).format(
      'L'
    )} ${dateSeparator}${dayjs(endDate).format('L')}`

    const numOfFiltersSelected =
      values.length > 0 && values.reduce((acc, value) => acc).filterSelect
        ? 1
        : null

    switch (props.filterType) {
      case 'dateRangePicker':
        return (
          <FormControl variant="outlined" className={classes.customSelect}>
            {!!numOfFiltersSelected && (
              <Chip
                color="primary"
                label={formattedStartAndEndDate}
                className={classes.selectFilterChip}
              ></Chip>
            )}

            <Input
              htmlFor={'datepicker'}
              value="Date"
              onClick={() => setOpenDateRangePicker(!openDateRangePicker)}
              label="DateRange"
              disableUnderline={true}
              className={classes.input}
            />

            {openDateRangePicker && (
              <DateRange
                ranges={dateRange}
                rangeColors={[ACC_THEME.palette.primary.main]}
                showSelectionPreview={true}
                onChange={(item) => {
                  onChangeDateRangePickerFilter(
                    item,
                    props.filterDataPath,
                    props.filterResultsLogic,
                    props.multipleValuesLogic
                  )
                  setOpenDateRangePicker(false)
                }}
                className={classes.datePicker}
              />
            )}
            {openDateRangePicker ? (
              <Icon
                onClick={() => setOpenDateRangePicker(false)}
                styles={classes.dateRangeArrowIcon}
              >
                ArrowDropUpIcon
              </Icon>
            ) : (
              <Icon
                onClick={() => setOpenDateRangePicker(true)}
                styles={classes.dateRangeArrowIcon}
              >
                ArrowDropDownIcon
              </Icon>
            )}
          </FormControl>
        )

      case 'groupingSelect':
        return (
          <FormControl variant="outlined" className={classes.customSelect}>
            {numOfFiltersSelected ? (
              <Chip
                color="primary"
                label={state}
                className={classes.selectFilterChip}
              ></Chip>
            ) : (
              <InputLabel htmlFor={groupedSelect}>Location</InputLabel>
            )}
            <Select
              className={classes.selectInput}
              defaultValue=""
              id={groupedSelect}
              onChange={(event) => {
                onChangeGroupingFilter(
                  event,
                  props.filterDataPath,
                  props.filterResultsLogic,
                  props.multipleValuesLogic
                )
              }}
            >
              <MenuItem value={null}>
                <TextSm>None</TextSm>
              </MenuItem>

              {isVirtualEvent && (
                <MenuItem value="virtualEvent">
                  <TextSm>Virtual Event</TextSm>
                </MenuItem>
              )}

              {!!CanadaAndUSCountryStates &&
                CanadaAndUSCountryStates.map((state) => {
                  let stateCount = 0
                  !!formattedEventStates &&
                    formattedEventStates.forEach((element) => {
                      element === state.name && stateCount++
                    })
                  if (state.country)
                    return (
                      <ListSubheader
                        key={state._uid}
                        disableSticky={true}
                        className={classes.listSubHeader}
                      >
                        {state.country}
                      </ListSubheader>
                    )

                  return (
                    <MenuItem key={state._uid} value={state.name}>
                      <TextSm>{state.name}</TextSm>
                      {!!stateCount && stateCount > 0 && (
                        <TextSm className={classes.stateCount}>
                          ({stateCount})
                        </TextSm>
                      )}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
        )

      default:
        return null
    }
  }

  return (
    <SbEditable content={props.blok}>
      <Box className={classes.filtersMenu}>
        {filterByText && (
          <TextSm className={classes.text}>{filterByText}</TextSm>
        )}
        <Grid
          container
          spacing={2}
          className={classNames(classes.filters, {
            [classes.fullWidthGrid]: !resetButtonExists,
          })}
        >
          {staticEventFilters &&
            staticEventFilters.map((filterField) => (
              <Grid
                item
                xs={8}
                sm={4}
                key={filterField._uid}
                className={classes.grid}
              >
                <GetFilterComponentByFilterType {...filterField} />
              </Grid>
            ))}
          {!!filtersList &&
            filtersList.map((filterField) => (
              <Grid
                className={classes.grid}
                item
                xs={12}
                sm={setButtonGrid(filterField, isResourcesModule)}
                key={filterField._uid}
              >
                {renderBlok({
                  ...filterField,
                  filterOptions: getFilterOptions(filterField.filterType),
                  filters: props.blok.filters,
                  onChangeFilters,
                  onChangeSearchText: props.blok.onChangeSearchText,
                  updateFilterLogicRules,
                  searchValue: searchValue,
                })}
              </Grid>
            ))}
        </Grid>
        <ResetButtonModule {...props} />
      </Box>
    </SbEditable>
  )
}
export default GalleryFiltersMenuModule
